<template>
  <div class="bg-zinc-100 h-dvh">
    <div class="bg-zinc-100 h-dvh flex flex-col gap-3 justify-center items-center">
      <div class="text-zinc-900 text-5xl font-bold">
        🕵️
      </div>
      <div class="text-zinc-900 text-4xl font-bold instrument-serif-regular">
        Riddlemoji
      </div>
      <div class="catchphrase text-zinc-900 text-lg font-medium my-3">
        Decipher the
        <div class="catchphrase__highlight">
          <span class="catchphrase__highlight-text">
            hidden
          </span>
          <div class="catchphrase__highlight-magnifier">
            🔍
          </div>
        </div>
        phrase.
      </div>
      <RouterLink to="/play" class="bg-zinc-900 hover:bg-zinc-700 text-zinc-50 m-2 rounded-full py-2 px-7 text-lg">
        Play
      </RouterLink>
      <div
        v-if="currentStreak > 0"
        class="text-zinc-800 text-center text-sm mb-3 px-3 py-2 font-medium border-2 border-zinc-300 border-dashed rounded-full">
        🔥 Current streak: {{ currentStreak }}
      </div>
      <div class="text-zinc-900 text-center">
        <div v-show="puzzleNumber.length" class="font-bold text-lg">
          Riddle #{{ puzzleNumber }} / {{ puzzleDate }}
        </div>
        <div class="text-xs">
          A daily game by
          <a class="text-zinc-950 font-medium" href="https://twitter.com/ctrl_alt_kaveet" target="_blank">
            Kaveet Laxmidas
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { getGame, getGameDate } from '../lib/api'
import { useToast } from 'vue-toastification'
import { captureException } from '../lib/metrics'
import { checkAndClearLostStreak, getCurrentStreak } from '../lib/state'
import { getOnboardedState } from '../store'
import { getTimeOfDayGreeting } from '../lib/time'

import GameParams from '../types/gameplay/GameParams'

const toast = useToast()

const puzzleNumber = ref<string>('')
const puzzleDate = ref<string>(new Date().toISOString().split('T')[0])
// Initialize to 0 because we need to check first
const currentStreak = ref<number>(0)

function checkStreak() {
  const streakCheckResult = checkAndClearLostStreak()

  if (streakCheckResult.didLose) {
    toast.info(`You lost your ${streakCheckResult.previousCount} day streak 😢`, {
      timeout: 3500,
    })
    return
  }

  const storedStreak = getCurrentStreak()
  currentStreak.value = storedStreak
}

function showRandomGreeting() {
  toast.info(getTimeOfDayGreeting(), {
    timeout: 3500,
  })
}

onMounted(async () => {
  let gameParams: GameParams

  try {
    gameParams = await getGame(getGameDate())
  } catch (err) {
    toast.error("Riddlemoji can't load! Try again?", {
      timeout: 10000,
      onClick() {
        window.location.reload()
      },
    })
    if (err instanceof Error) {
      captureException(err)
    }
    return
  }

  puzzleNumber.value = gameParams.number
  puzzleDate.value = gameParams.date
  checkStreak()
  if (getOnboardedState('0')) {
    showRandomGreeting()
  }
})
</script>

<style scoped lang="scss">
.catchphrase {
  position: relative;

  &__highlight {
    display: inline-block;
    position: relative;
  }

  &__highlight-text {
    animation: blur-reveal 4s forwards;
    filter: blur(3px);
  }

  &__highlight-magnifier {
    animation: mag-swipe 4s forwards;
    font-size: 1.5em;
    left: 50%;
    opacity: 0;
    position: absolute;
    top: -50%;
    transform: translate(-50%, -50%);
  }
}

@keyframes mag-swipe {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%);
  }

  25% {
    opacity: 1;
  }

  50% {
    transform: translate(-50%, 60%) rotate(72deg);
  }

  70% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translate(-50%, 150%);
  }
}

@keyframes blur-reveal {
  0% {
    filter: blur(3px);
  }

  85% {
    filter: blur(3px);
  }

  100% {
    filter: blur(0px);
  }
}
</style>

<template>
  <div class="play flex flex-col h-dvh">
    <div style="height: 75px;"
      class="play__heading-area fixed w-full top-0 left-0 bg-zinc-50/50 backdrop-blur-sm z-50 shadow-sm">
      <div class="text-zinc-900 container mx-auto p-4 flex justify-between items-center h-full">
        <div class="text-2xl instrument-serif-regular text-zinc-900">
          <router-link to="/" class="">
            🕵️
            Riddlemoji
          </router-link>
        </div>
        <div class="text-sm">
          <span class="font-semibold">#{{ gameParams.number }}</span>
          /
          <span class="font-semibold">{{ gameParams.date }}</span>
        </div>
      </div>
    </div>

    <div style="padding-top: 85px;" class="container md:w-1/2 lg:w-1/3 mx-auto">

      <div v-if="state !== GameplayState.Playing" class="w-full p-3">
        <div class="bg-zinc-900 text-zinc-50 p-3 rounded-md">
          <NextClueTimer />
        </div>
      </div>

      <div v-if="state !== GameplayState.Playing" class="play__results-area p-3">

        <!-- Solved -->
        <div v-if="state === GameplayState.Solved" class="text-zinc-900 rounded-md bg-zinc-50 p-3 shadow-sm">
          <div class="text-center py-3">
            <span class="font-semibold">Solved!</span>
            <div class="text-sm">
              You solved #{{ gameParams.number }} in {{ guesses.length }} guess{{ guesses.length > 1 ? 'es' :
                '' }}.
            </div>
          </div>
          <div class="w-full py-3">
            <Stats :stats="stats" />
          </div>
          <div class="play__results-actions w-full flex justify-center items-center gap-2 py-3">
            <button @click="handleShare"
              class="bg-zinc-900 text-zinc-50 rounded-full px-4 py-2 text-sm animate-pulse hover:animate-none">
              Share Stats
            </button>
          </div>
        </div>

        <!-- Gave Up -->
        <div v-else-if="state === GameplayState.GaveUp" class="text-zinc-900 rounded-md bg-zinc-50 p-3 shadow-sm">
          <div class="text-center py-3">
            <span class="font-semibold">Gave Up!</span>
            <div class="text-sm">
              Today's answer was:
            </div>
            <div class="text-lg py-2 font-medium">
              {{ gaveUpAnswer }}
            </div>
          </div>
        </div>
      </div>

      <div class="play__clue-area p-3">
        <div class="play__clue-area-instruction text-center py-2">
          <span class="font-semibold">
            Riddle:
          </span>
          {{ gameParams.riddle }}
        </div>
        <div class="play__clue-area-clue w-full p-3 flex justify-center">
          <EmojiList :items="gameParams.clues[activeClueIndex]" class="flex justify-center" />
        </div>
      </div>
      <div v-if="state === GameplayState.Playing" class="play__input-area p-3">
        <div class="play__input-decoration text-sm py-2 px-1 flex justify-between">
          <div class="flex gap-2">
            <span class="font-semibold">Guesses:</span>
            <span>
              {{ guesses.length }}
            </span>
          </div>
          <div class="flex gap-4">
            <ConfirmLink v-show="guesses.length >= Math.min(gameParams.clues.length, 3)" @confirm="handleGiveUp"
              class="text-zinc-900 underline underline-offset-2 font-semibold" defaultText="Give Up?"
              confirmText="Are you sure? :(" confirmedText="Better luck next time!" />
            <a @click="openHelp" href="#" class="text-zinc-900 underline underline-offset-2 font-semibold">Help</a>
          </div>
        </div>
        <div class="flex gap-3 items-center">
          <input v-model="guess" @keydown.enter="submitGuess"
            class="p-3 h-12 rounded-md bg-zinc-50 text-zinc-900 focus:outline-none focus:ring-0 shadow-sm flex-1"
            type="text" enterkeyhint="go" :disabled="state !== GameplayState.Playing" placeholder="Type your guess" />
          <button :disabled="!guess || isGuessSubmitting" @click="submitGuess"
            class="bg-zinc-50 hover:bg-zinc-100 h-12 text-zinc-900 rounded-md p-3 text-sm shadow-sm">
            Submit
          </button>
        </div>
      </div>
      <div class="play-area flex-1 p-3">
        <Guess v-for="guess in guesses.slice().reverse()" :key="guess.guess" :guess="guess.guess" :score="guess.score"
          class="mb-2" />
      </div>
    </div>
  </div>
  <Onboarding v-if="showHelp" @dismiss="dismissHelp" />
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import copy from 'copy-to-clipboard'
import { useToast } from 'vue-toastification'

import GameplayState from '../types/GameplayState'
import GameParams from '../types/gameplay/GameParams'
import ScoredGuess from '../types/ScoredGuess'

import { captureException, trackEvent } from '../lib/metrics'
import { clearStreak, getSavedGameState, recordStreakWin, setSavedGameState } from '../lib/state'
import { getGame, getScore, getGameDate, getAnswer } from '../lib/api'
import { MAX_SCORE, getBars, getColor, normalizeScore } from '../lib/gameplay'
import { getOnboardedState, setOnboardedState } from '../store'

import Guess from '../components/Guess.vue'
import Stats from '../components/Stats.vue'
import Onboarding from '../components/Onboarding.vue'
import ConfirmLink from '../components/ConfirmLink.vue'
import NextClueTimer from '../components/NextGameTimer.vue'
import EmojiList from '../components/EmojiList.vue'

const toast = useToast()

// State
const guess = ref('')
const gaveUpAnswer = ref('')
const gameParams = ref<GameParams>({
  date: getGameDate(),
  number: '0',
  clues: [],
  riddle: '',
})

const showHelp = ref(false)
const isGuessSubmitting = ref(false)

const state = ref<GameplayState>(GameplayState.Playing)
const guesses = ref<ScoredGuess[]>([])
const activeClueIndex = ref(0)

async function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

function openHelp() {
  showHelp.value = true
}

function dismissHelp() {
  showHelp.value = false
  setOnboardedState(true, '0')
}

async function handleGiveUp() {
  trackEvent('riddle.give_up', { game: gameParams.value.number })

  getAnswer(getGameDate())
    // Do this asynchronously
    // to improve perceived performance
    .then((answer) => {
      gaveUpAnswer.value = answer
    })

  // Animate through the remaining clues
  for (let i = activeClueIndex.value; i < gameParams.value.clues.length; i++) {
    await sleep(750)
    incrementClue()
  }

  state.value = GameplayState.GaveUp
  window.scrollTo({ top: 0, behavior: 'smooth' })

  if (clearStreak()) {
    toast.info('You lost your win streak 😢', {
      timeout: 3500,
    })
  }

  setSavedGameState({
    date: gameParams.value.date,
    guesses: guesses.value,
    state: state.value,
  })
}

async function handleSolve() {
  trackEvent('riddle.solve', { game: gameParams.value.number })

  // Animate through the remaining clues
  for (let i = activeClueIndex.value; i < gameParams.value.clues.length; i++) {
    await sleep(750)
    incrementClue()
  }

  state.value = GameplayState.Solved
  window.scrollTo({ top: 0, behavior: 'smooth' })

  const newStreak = recordStreakWin()
  if (newStreak > 0) {
    toast.info(`🔥 New streak: ${newStreak} day${newStreak > 1 ? 's' : ''}`, {
      timeout: 3500,
    })
  }
}

async function submitGuess() {
  if (!guess.value) {
    return
  }

  const normalizedGuess = guess.value.trim().toLocaleLowerCase()

  if (normalizedGuess.length === 0) {
    guess.value = ''
    return
  }

  if (guesses.value.find((g) => g.guess === normalizedGuess)) {
    toast.info('Already guessed!')
    guess.value = ''
    return
  }

  trackEvent('riddle.guess', { game: gameParams.value.number })

  isGuessSubmitting.value = true

  guesses.value.push({
    guess: normalizedGuess,
    score: Math.round(MAX_SCORE / 2), // FIXME: MAX_SCORE isn't calibrated
  })

  guess.value = ''

  let normalizedScore

  try {
    const rawScore = await getScore(normalizedGuess)
    normalizedScore = normalizeScore(rawScore)
  } catch (err) {
    toast.error("Your guess wasn't submitted. Try again!")
    await sleep(1500)
    guesses.value = guesses.value.slice(0, -1)
    if (err instanceof Error) {
      captureException(err)
    }
    return
  }

  guesses.value[guesses.value.length - 1].score = normalizedScore
  isGuessSubmitting.value = false

  // Win
  if (normalizedScore === 0) {
    await handleSolve() 
  } else {
    if (getColor(normalizedScore) === 'green') {
      toast.info("You're close!")
    }
    await sleep(1500)
    incrementClue()
  }

  // Do this last, after all state changes have settled
  setSavedGameState({
    date: gameParams.value.date,
    guesses: guesses.value,
    state: state.value,
  })
}

function incrementClue() {
  activeClueIndex.value = Math.min(activeClueIndex.value + 1, gameParams.value.clues.length - 1)
}

function handleShare() {
  trackEvent('riddle.share.click', { game: gameParams.value.number })

  const bars = getBars(stats.value)
  const text = [
    `I solved Riddlemoji.com #${gameParams.value.number} in ${guesses.value.length} guess${guesses.value.length > 1 ? 'es' : ''}!`,
    '',
    `${bars.green} ${stats.value.green}`,
    `${bars.yellow} ${stats.value.yellow}`,
    `${bars.red} ${stats.value.red}`,
  ].join('\n')

  const shareData = {
    title: 'Riddlemoji Stats',
    text,
    url: 'https://riddlemoji.com',
  }

  if (navigator.share && navigator.canShare(shareData)) {
    navigator.share(shareData)
      .then(() => {
        trackEvent('riddle.share.web_share', { game: gameParams.value.number })
      })
      .catch((err) => {
        // Cancelled by user
        if (err.name === 'AbortError') {
          copy(text)
          toast.info('Copied to clipboard!')
        } else {
          throw err
        }
      })
  } else {
    copy(text)
    toast.info('Copied to clipboard!')
  }
}

const stats = computed(() => {
  return guesses.value.reduce((acc, guess) => {
    const color = getColor(guess.score)
    switch (color) {
      case 'red':
        acc.red++
        break
      case 'yellow':
        acc.yellow++
        break
      case 'green':
        acc.green++
        break
    }
    return acc
  }, { red: 0, yellow: 0, green: 0 })
})

async function restoreGameState() {
  const savedGameplayState = getSavedGameState(getGameDate())

  if (savedGameplayState) {
    state.value = savedGameplayState.state
    guesses.value = savedGameplayState.guesses

    if (savedGameplayState.state === GameplayState.Solved) {
      activeClueIndex.value = gameParams.value.clues.length - 1
    } else if (savedGameplayState.state === GameplayState.Playing) {
      activeClueIndex.value = Math.min(Math.max(savedGameplayState.guesses.length , 0), gameParams.value.clues.length - 1)
    } else if (savedGameplayState.state === GameplayState.GaveUp) {
      activeClueIndex.value = gameParams.value.clues.length - 1
      gaveUpAnswer.value = await getAnswer(getGameDate())
    }
  }
}

async function setUpGameParams() {
  const gameRes = await getGame(getGameDate())
  gameParams.value = gameRes
}

function setUpOnboarding() {
  const isOnboarded = getOnboardedState('0')
  // New users get a bonus clue
  if (!isOnboarded) {
    showHelp.value = true
    activeClueIndex.value = 1
  }
}

onMounted(async () => {
  // Must wait
  // FIXME: Hoist this to global state
  await setUpGameParams()
  setUpOnboarding()
  restoreGameState()
})
</script>

<style scoped lang="scss">
.play__clue-area-clue-symbol {
  animation: blurReveal 0.8s forwards;
  filter: blur(5px);
}

@keyframes blurReveal {
  0% {
    filter: blur(5px);
  }

  100% {
    filter: blur(0px);
  }
}

.list-enter-active,
.list-leave-active {
  transition: all 0.8s linear;
}

.list-enter-from {
  filter: blur(5px);
}

.list-leave-to {
  filter: blur(5px);
}
</style>

import GameplayState from '../types/GameplayState'
import LostStreak from '../types/LostStreak'
import SavedGameState from '../types/SavedGameState'
import ScoredGuess from '../types/ScoredGuess'
import StreakState from '../types/StreakState'
import { getGameDate, getYesterdayGameDate } from './api'
import { captureException, trackEvent } from './metrics'

const NAMESPACE = 'riddlemoji'
/**
 * If this is incremented, previous game states will be invalidated.
 * Do this when the game state format changes.
 */
const GAME_STATE_VERSION = 1

/**
 * If this is incremented, previous streak states will be invalidated.
 * Do this when the streak state format changes.
 */
const STREAK_STATE_VERSION = 1

// In the future, we may want to hold on to the last N game states, but for now we'll just keep the current one.
function removePreviousGameStates(exceptForDate: string) {
  try {
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith(`${NAMESPACE}:gameState:`)) {
        const state = JSON.parse(localStorage.getItem(key) as string) as SavedGameState
        if (state.date !== exceptForDate) {
          localStorage.removeItem(key)
          console.debug(`Removed old game state for ${state.date}`)
        }
        else if (state.version !== GAME_STATE_VERSION) {
          localStorage.removeItem(key)
          console.debug(`Removed game state for ${state.date} due to schema invalidation`)
        }
      }
    })
  } catch (err) {
    if (err instanceof Error) {
      captureException(err)
    }
  }
}

export function setSavedGameState(args: ({
  date: string,
  guesses: ScoredGuess[],
  state: GameplayState
})) {
  try {
    removePreviousGameStates(args.date)
    const state: SavedGameState = {
      version: GAME_STATE_VERSION,
      ...args
    }
    localStorage.setItem(`${NAMESPACE}:gameState:${args.date}`, JSON.stringify(state))
  } catch (err) {
    if (err instanceof Error) {
      captureException(err)
    }
  }
}

export function getSavedGameState(date: string): SavedGameState | null {
  try {
    removePreviousGameStates(date)
    const state = localStorage.getItem(`${NAMESPACE}:gameState:${date}`)
    if (state) {
      return JSON.parse(state) as SavedGameState
    }
    return null
  } catch (err) {
    if (err instanceof Error) {
      captureException(err)
    }
    return null
  }
}

export function getStreakState(): StreakState | null {
  try {
    const state = localStorage.getItem(`${NAMESPACE}:streakState`)
    if (state) {
      return JSON.parse(state) as StreakState
    }
    return null
  } catch (err) {
    if (err instanceof Error) {
      captureException(err)
    }
    return null
  }
}

export function getCurrentStreak(): number {
  const streakState = getStreakState()
  if (streakState) {
    return streakState.count
  }
  return 0
}

/**
 * @returns {number} The new streak count
 */
export function recordStreakWin(): number {
  const today = getGameDate()
  const yesterday = getYesterdayGameDate()
  const streakState = getStreakState()

  // Case 1: No existing state
  if (!streakState) {
    const newState: StreakState = {
      version: STREAK_STATE_VERSION,
      count: 1,
      lastWin: today
    }
    try {
      localStorage.setItem(`${NAMESPACE}:streakState`, JSON.stringify(newState))
      trackEvent('streak.update', { count: newState.count })
      return newState.count
    } catch (err) {
      if (err instanceof Error) {
        captureException(err)
      }
    }
    // If we can't save the state,
    // we won't ever be able to maintain streaks.
    return 0
  }

  // Case 2: Existing state, was yesterday
  if (streakState.lastWin === yesterday) {
    const newState: StreakState = {
      version: STREAK_STATE_VERSION,
      count: streakState.count + 1,
      lastWin: today
    }
    try {
      localStorage.setItem(`${NAMESPACE}:streakState`, JSON.stringify(newState))
      trackEvent('streak.update', { count: newState.count })
      return newState.count
    } catch (err) {
      if (err instanceof Error) {
        captureException(err)
      }
    }
    // If we can't save the state,
    // we won't ever be able to maintain streaks.
    return 0
  }

  // Case 3: Existing state, is today
  if (streakState.lastWin === today) {
    return streakState.count
  }

  // Case 4: Existing state, was not yesterday
  const newState: StreakState = {
    version: STREAK_STATE_VERSION,
    count: 1,
    lastWin: today
  }

  try {
    localStorage.setItem(`${NAMESPACE}:streakState`, JSON.stringify(newState))
    trackEvent('streak.update', { count: newState.count })
    return newState.count
  } catch (err) {
    if (err instanceof Error) {
      captureException(err)
    }
  }

  // If we can't save the state,
  // we won't ever be able to maintain streaks.
  return 0
}

/**
 * @returns {boolean} True if a streak was cleared
 */
export function clearStreak(): boolean {
  const streakState = getStreakState()

  if (!streakState) {
    return false
  }

  try {
    localStorage.removeItem(`${NAMESPACE}:streakState`)
    return true
  } catch (err) {
    if (err instanceof Error) {
      captureException(err)
    }
  }

  // If we can't save the state,
  // we won't ever be able to maintain streaks.
  return false
}

/**
 * Evaluate whether the player has lost their streak
 * by not playing yesterday.
 * 
 * @returns {boolean} True if the player lost their streak
 */
export function checkAndClearLostStreak(): LostStreak {
  const today = getGameDate()
  const yesterday = getYesterdayGameDate()
  const streakState = getStreakState()

  // Case 1: No existing state
  if (!streakState) {
    return {
      didLose: false,
      previousCount: 0
    }
  }

  // Case 2: Existing state, was yesterday
  if (streakState.lastWin === yesterday) {
    return {
      didLose: false,
      previousCount: streakState.count
    }
  }

  // Case 3: Existing state, is today
  if (streakState.lastWin === today) {
    return {
      didLose: false,
      previousCount: streakState.count
    }
  }

  // Case 4: Existing state, was not yesterday
  clearStreak()
  trackEvent('streak.lost')
  return {
    didLose: true,
    previousCount: streakState.count
  }
}